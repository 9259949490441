import React, { useState } from 'react'
import { graphql } from 'gatsby'
import { v4 } from 'uuid'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { Box, Button, Flex, Stack } from '@chakra-ui/react'

import Layout from '../components/layout'

import { Article, Section, FeatureItem, FeatureList, Image, Gallery, P } from '../ui'
import FormContact from '../components/formContact'

function ToursPage({ data }) {
  const { t } = useTranslation()
  const [selectedTour, setSelectedTour] = useState('')

  const onSubmit = async (values, setSuccess) => {
    const body = values
    body.to = 'info@hotelmayansecret.com'

    const res = await fetch(`https://form-server.vercel.app/api`, {
      headers: {
        Accept: 'application/json',
      },
      method: 'post',
      body: JSON.stringify(body),
    })

    if (res.ok) setSuccess(true)
    else setSuccess(false)
  }

  return (
    <Layout title={t('tours:title')}>
      <Article>
        <Section
          title={t('tours:title')}
          subtitle={t('tours:desc')}
          titleAlign="center"
          paddingBottom="0 !important"
        />
        {t('tours:tours', { returnObjects: true }).map((tour) => (
          <Section key={v4()} title={tour.title} titleAlign="center" whiteSpace="pre-wrap">
            <Stack
              maxWidth="560px"
              marginX="auto"
              align="center"
              justify="center"
              marginBottom={4}
              marginTop={-2}
            >
              <P>{tour.desc}</P>
              <Button as="a" href="#contact" width="fit-content" colorScheme="primary">
                {t('tours:button')}
              </Button>
            </Stack>
            <Gallery
              images={data[tour.id].edges.map((image) => (
                <Image key={v4()} fixed={image.node.childImageSharp.fixed} layout="background" />
              ))}
              buttonText={t('common:viewPhotos')}
            />
          </Section>
        ))}

        <Section
          id="contact"
          title={t('experience:contact.title')}
          subtitle={t('experience:contact.desc')}
          titleAlign="center"
          backgroundColor="gray.100"
        >
          <Box
            backgroundColor="white"
            boxShadow="0px 0px 10px rgba(0,0,0,0.1)"
            padding={{ base: 6, md: 8 }}
            borderRadius="15px"
            maxWidth={{ base: '100%', md: '720px' }}
            marginX={{ base: 0, md: 'auto' }}
            width="100%"
          >
            <FormContact
              hideOnSuccess
              onSubmit={(values, setSuccess) => onSubmit(values, setSuccess)}
              name={{
                label: t('contact:form.inputs.name.label'),
                required: t('contact:form.inputs.name.required'),
                invalid: t('contact:form.inputs.name.invalid'),
                placeholder: t('contact:form.inputs.name.placeholder'),
              }}
              email={{
                required: t('contact:form.inputs.email.required'),
                invalid: t('contact:form.inputs.email.invalid'),
              }}
              message={{
                defaultValue: selectedTour,
                label: t('contact:form.inputs.message.label'),
                required: t('contact:form.inputs.message.required'),
                invalid: t('contact:form.inputs.message.invalid'),
                placeholder: t('contact:form.inputs.message.placeholder'),
              }}
              successText={t('contact:form.success')}
              buttonText={t('contact:form.inputs.button')}
            />
          </Box>
        </Section>
      </Article>
    </Layout>
  )
}

export const query = graphql`
  query {
    citytour: allFile(
      filter: { relativeDirectory: { eq: "tours/galleries/citytour" } }
      sort: { fields: name }
    ) {
      ...multipleFixedImage
    }

    bacalar: allFile(
      filter: { relativeDirectory: { eq: "tours/galleries/bacalar" } }
      sort: { fields: name }
    ) {
      ...multipleFixedImage
    }

    masks: allFile(
      filter: { relativeDirectory: { eq: "tours/galleries/masks" } }
      sort: { fields: name }
    ) {
      ...multipleFixedImage
    }

    riobec: allFile(
      filter: { relativeDirectory: { eq: "tours/galleries/riobec" } }
      sort: { fields: name }
    ) {
      ...multipleFixedImage
    }

    lagos: allFile(
      filter: { relativeDirectory: { eq: "tours/galleries/lagos" } }
      sort: { fields: name }
    ) {
      ...multipleFixedImage
    }

    lamanai: allFile(
      filter: { relativeDirectory: { eq: "tours/galleries/lamanai" } }
      sort: { fields: name }
    ) {
      ...multipleFixedImage
    }

    altun: allFile(
      filter: { relativeDirectory: { eq: "tours/galleries/altun" } }
      sort: { fields: name }
    ) {
      ...multipleFixedImage
    }

    tikal: allFile(
      filter: { relativeDirectory: { eq: "tours/galleries/tikal" } }
      sort: { fields: name }
    ) {
      ...multipleFixedImage
    }

    mayans: allFile(
      filter: { relativeDirectory: { eq: "tours/galleries/mayans" } }
      sort: { fields: name }
    ) {
      ...multipleFixedImage
    }

    calakmul: allFile(
      filter: { relativeDirectory: { eq: "tours/galleries/calakmul" } }
      sort: { fields: name }
    ) {
      ...multipleFixedImage
    }
  }
`

export default ToursPage
